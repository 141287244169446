import axios from '@/services/axios_auth'
import Query from '../assets/js/query'
import {CHUNK_SIZES} from '../assets/js/constants'
import {arrayOfObjectsGroupBy, cleanUpSearchTerm} from '../assets/js/utils'
import {INCH_FILTER} from '~/assets/js/filters'
import {useUserStore} from '@/store/user'
// Diesen Import nicht löschen, wird benötigt für Unit Tests. ¯\_(ツ)_/¯
// eslint-disable-next-line no-unused-vars
import * as productService from './product_service'
import {useAddressHelper} from '@/composables/address_helper'
import {getDesignQuery, getDesignQueryNew, getDesignQueryForCategory, getDesignQueryForCategoryNew, getSelectByScope, getProductQueryParams, buildFilterUrl} from './product_service_helper'
import {mapProductAttributeNames, getMappedProductNew} from '~/assets/js/utils/product_attributes_mapping'

export const useProductService = () => {

  const baseurl = useRuntimeConfig().public.BASIC_API_URL  || 'https://api-stg.dev.fst.com'
  const {getDeliveryAddress} = useAddressHelper()

  let call = null

  const product_axios = axios.create({
    baseURL: useRuntimeConfig().public.PRODUCTS_API || (baseurl + '/products-api/1.1.0')
  })
  //for new experimental search
  const product_axios_new = axios.create({
    baseURL: useRuntimeConfig().public.PRODUCTS_API || (baseurl + '/products-api/2.0.0')
  })

  const getProductBySku = async function (sku, region, lang, showInchValues = false, alternativePlantCode = null, selectScope = null) {
    let select = getSelectByScope(selectScope, [], useUserStore().loggedIn)
    let url = `/products(${sku})`
    let filters = []

    if (showInchValues) {
      filters.push('measurement eq \'inch\'')
    }
    if (alternativePlantCode) {
      filters.push(`alternativePlantCode eq ${alternativePlantCode}`)
    }
    const deliveryAddress = getDeliveryAddress()
    if (deliveryAddress && (deliveryAddress.plantCode !== '' || deliveryAddress.erpReference !== null)) {
      if (deliveryAddress.plantCode !== '') {
        filters.push(`addressPlantCode eq '${deliveryAddress.plantCode}'`)
      }
      if (deliveryAddress.erpReference !== null) {
        filters.push(`addressErpReference eq '${deliveryAddress.erpReference}'`)
      }
    }

    if (filters.length > 0) {
      url = `${url}?apply=filter(`
      url = buildFilterUrl(url, filters)
    }
    else {
      url = `${url}`
    }

    if (select) {
      let selectString = `$select=${select.join(',')}`
      if (url.includes('?')) {
        url += `&${selectString}`
      } else {
        url += `?${selectString}`
      }
    }

    const res = await product_axios.get(url, {headers: {fstRegion: region, contentLanguage: lang}} ).catch(() => {return null})
    return res ? res.data : null
  }

  const getProductBySkuNew = async function (sku, region, lang, showInchValues = false, alternativePlantCode = null, selectScope = null) {
    let select = getSelectByScope(selectScope, [], useUserStore().loggedIn)
    if (showInchValues) {
      select.push('locales_' + lang.toUpperCase() + '_Imperial_SalesTextECom')
    } else {
      select.push('locales_' + lang.toUpperCase() + '_Metric_SalesTextECom')
    }
    select.push('locales_' + lang.toUpperCase() + '_SalesText')
    let filters = ['pdpCall eq true']

    if (showInchValues) {
      filters.push('measurement eq \'inch\'')
    }
    if (alternativePlantCode) {
      filters.push(`alternativePlantCode eq ${alternativePlantCode}`)
    }
    let filterString
    if (filters.length > 0) {
      filterString = buildFilterUrl('(', filters)
    }
    let testingString = '`sku eq \'' + sku + '\'`'
    const options = {
      pagination: {
        take: 1,
        skip: 0
      },
      sort: []
    }
    let query = new Query().where(testingString).select(select).includeCount()
    const params = getProductQueryParams(query, [], [], options, null, true)
    let result = await getProductListNew(region, lang, params, false, false, [filterString])
    return result.searchResults?.length > 0 ? getMappedProductNew(result.searchResults[0]) : null
  }

  const getProductsBySkus = async function (skus, region, lang, selectScope = null, customerKey = null, anonymous = false) {
    if (!Array.isArray(skus)) {
      return []
    }

    let select = getSelectByScope(selectScope, [], useUserStore().loggedIn)

    let skuObjects
    if (skus && skus.length > 0) {
      if (typeof skus[0] === 'object') {
        skuObjects = skus
      } else {
        skuObjects = skus.map(s => { return {sku: s, plantCode: 'null'} })
      }
    }

    let products = []
    const chunkSize = CHUNK_SIZES.ProductService
    skuObjects = arrayOfObjectsGroupBy(skuObjects, 'plantCode')
    let parallelApiCalls = []
    if (skuObjects && typeof skuObjects !== 'undefined') {
      for (const [key, arrayOfPlantCode] of Object.entries(skuObjects)) {
        for (let i = 0; i < arrayOfPlantCode.length; i += chunkSize) {
          const skuChunk = arrayOfPlantCode.slice(i, i + chunkSize)
          let listOfSkus = skuChunk?.map(s => s && s?.sku ? s?.sku : null).filter(f => f !== null)
          const joinedSkus = listOfSkus.join(',')
          let searchIn = '`search.in(Sku, \'' + joinedSkus + '\')`'
          if (customerKey) {
            searchIn += ' or `CustomerMappings/any(m: m/Key eq \'' + customerKey + '\' and search.in(m/Number, \'' + joinedSkus + '\'))`'
          }
          let applyCustomFilters = []
          if (key && key !== 'null' && key !== 'undefined') {
            applyCustomFilters.push(` and alternativePlantCode='${key}'`)
          }
          const params = new Query().where(searchIn).select(select).top(listOfSkus.length).toPostParams()
          parallelApiCalls.push(getProductList(region, lang, params, false, false, applyCustomFilters, anonymous))
        }
      }
      await Promise.all(parallelApiCalls).then(results => {
        results.forEach(res => {
          if (res && res.products.length > 0) {
            products = products.concat(res.products)
          }
        })
      }).catch(() => {return null})
    }

    return products
  }

  const getProductsBySkusNew = async function (skus, region, lang, selectScope = null, customerKey = null, anonymous = false) {
    if (!Array.isArray(skus)) {
      return []
    }

    let select = getSelectByScope(selectScope, [], useUserStore().loggedIn)
    select?.push('locales_' + lang.toUpperCase() + '_SalesText')
    let skuObjects
    if (skus && skus.length > 0) {
      if (typeof skus[0] === 'object') {
        skuObjects = skus
      } else {
        skuObjects = skus.map(s => { return {sku: s, plantCode: 'null'} })
      }
    }

    let products = []
    const chunkSize = CHUNK_SIZES.ProductService
    skuObjects = arrayOfObjectsGroupBy(skuObjects, 'plantCode')
    let parallelApiCalls = []
    if (skuObjects && typeof skuObjects !== 'undefined') {
      for (const [key, arrayOfPlantCode] of Object.entries(skuObjects)) {
        for (let i = 0; i < arrayOfPlantCode.length; i += chunkSize) {
          const skuChunk = arrayOfPlantCode.slice(i, i + chunkSize)
          let listOfSkus = skuChunk?.map(s => s?.sku ?? null).filter(f => f !== null)
          const joinedSkus = listOfSkus.join(',')
          let searchIn = '`search.in(sku, \'' + joinedSkus + '\')`'
          if (customerKey) {
            searchIn += ' or `CustomerMappings/any(m: m/Key eq \'' + customerKey + '\' and search.in(m/Number, \'' + joinedSkus + '\'))`'
          }
          let applyCustomFilters = []
          if (key && key !== 'null' && key !== 'undefined') {
            applyCustomFilters.push(` and alternativePlantCode='${key}'`)
          }
          const params = new Query().where(searchIn).select(select).top(listOfSkus.length).toPostParams()
          parallelApiCalls.push(getProductListNew(region, lang, params, false, false, applyCustomFilters, anonymous))
        }
      }
      await Promise.all(parallelApiCalls).then(results => {
        results.forEach(res => {
          if (res && res.searchResults.length > 0) {
            const mappedResults = res.searchResults.map(product => getMappedProductNew(product))
            products = products.concat(mappedResults)
          }
        })
      }).catch(() => {return null})
    }

    return products
  }

  const getProductList = async function (region, lang, params, count, cancellable, applyCustomFilters = [], anonymous = false) {
    let requestParams = count ? {'count': true, ...params} : params
    const deliveryAddress = getDeliveryAddress()
    let filters = []
    if (deliveryAddress && (deliveryAddress.plantCode !== '' || deliveryAddress.erpReference !== null)) {
      if (deliveryAddress.plantCode !== '') {
        filters.push(`addressPlantCode eq '${deliveryAddress.plantCode}'`)
      }
      if (deliveryAddress.erpReference !== null) {
        filters.push(`addressErpReference eq '${deliveryAddress.erpReference}'`)
      }
    }

    if (applyCustomFilters && Array.isArray(applyCustomFilters)) {
      filters = filters.concat(applyCustomFilters)
    }

    if (filters.length > 0) {
      let apply = 'filter('
      apply = buildFilterUrl(apply, filters)
      requestParams = {...requestParams, apply: apply}
    }

    if (call && cancellable) {
      call.cancel('request canceled')
    }
    call = axios.CancelToken.source()
    let headers = {fstRegion: region, contentLanguage: lang}
    if (anonymous) {
      headers = {...headers, 'Authorization': ''}
    }
    const cancelToken = call && call.token
    let res
    const isSearch = 'search' in requestParams
    const isFilter = 'filter' in requestParams
    if (!isSearch && isFilter) {
      res = await product_axios.post('/products/lists/$bulkRequest',
        requestParams, {headers, cancelToken})
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e)
          return null
        })
    } else {
      res = await product_axios.post('/products/$bulkRequest',
        requestParams, {headers, cancelToken})
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e)
          return null
        })
    }
    return res ? res.data : null
  }
  //for new experimental search
  const getProductListNew = async function (region, lang, params, count, cancellable, applyCustomFilters = [], anonymous = false) {
    let requestParams = count ? {'count': true, ...params} : params
    const deliveryAddress = getDeliveryAddress()
    let filters = []
    if (deliveryAddress && (deliveryAddress.plantCode !== '' || deliveryAddress.erpReference !== null)) {
      if (deliveryAddress.plantCode !== '') {
        filters.push(`addressPlantCode eq '${deliveryAddress.plantCode}'`)
      }
      if (deliveryAddress.erpReference !== null) {
        filters.push(`addressErpReference eq '${deliveryAddress.erpReference}'`)
      }
    }

    if (applyCustomFilters && Array.isArray(applyCustomFilters)) {
      filters = filters.concat(applyCustomFilters)
    }

    if (filters.length > 0) {
      let apply = 'filter('
      apply = buildFilterUrl(apply, filters)
      requestParams = {...requestParams, apply: apply}
    }

    // cleanup sku with regexp
    const search = requestParams.search
    if (search && search !== '') {
      requestParams.search = cleanUpSearchTerm(search, lang)
    }

    if (call && cancellable) {
      call.cancel('request canceled')
    }
    call = axios.CancelToken.source()
    let headers = {fstRegion: region, contentLanguage: lang}
    if (anonymous) {
      headers = {...headers, 'Authorization': ''}
    }
    const cancelToken = call && call.token
    let res
    const isSearch = 'search' in requestParams
    const isFilter = 'filter' in requestParams
    if (!isSearch && isFilter) {
      res = await product_axios_new.post('/products/$bulkRequest',
        requestParams, {headers, cancelToken})
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e)
          return null
        })
    } else {
      res = await product_axios_new.post('/products/$bulkRequest',
        requestParams, {headers, cancelToken})
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e)
          return null
        })
    }
    return res ? res.data : null
  }

  const getProductsForDesigns = async function (designs, facets, region, lang, filters, options, applyCustomFilters = [], selectScope = null, attributeSelects = []) {
    let designWhereQuery = null
    if (Array.isArray(designs)) {
      designWhereQuery = getDesignQuery(designs)
    }
    else if (typeof designs === 'string') {
      designWhereQuery = getDesignQueryForCategory(designs)
    }
    if (designWhereQuery === null) {
      return null
    }
    let select = getSelectByScope(selectScope, attributeSelects, useUserStore().loggedIn)

    let query = new Query().where(designWhereQuery).select(select).includeCount()
    const params = getProductQueryParams(query, facets, filters, options, null)
    return getProductList(region, lang, params, false, false, applyCustomFilters)
  }

  const getProductsForDesignsNew = async function (designs, facets, region, lang, filters, options, applyCustomFilters = [], selectScope = null, attributeSelects = []) {
    const mappedFilters = filters?.map(filter => ({...filter, shortName: mapProductAttributeNames(filter.shortName), indexPath: mapProductAttributeNames(filter.indexPath)}))
    const mappedFacets = facets?.map(facet => mapProductAttributeNames(facet))
    let designWhereQuery = null
    if (Array.isArray(designs)) {
      designWhereQuery = getDesignQueryNew(designs)
    }
    else if (typeof designs === 'string') {
      designWhereQuery = getDesignQueryForCategoryNew(designs)
    }
    if (designWhereQuery === null) {
      return null
    }
    const cleanedAttributeSelects = attributeSelects?.map(attr => mapProductAttributeNames(attr)).filter(attr => !attr.startsWith('characteristics'))

    let select = getSelectByScope(selectScope, cleanedAttributeSelects, useUserStore().loggedIn)
    const inchSearch = applyCustomFilters?.includes(INCH_FILTER.customQuery)
    if (inchSearch) {
      select.push('locales_' + lang.toUpperCase() + '_Imperial_SalesTextECom')
    } else {
      select.push('locales_' + lang.toUpperCase() + '_Metric_SalesTextECom')
    }
    let query = new Query().where(designWhereQuery).select(select).includeCount()
    const mappedSortOptions = options.sort?.map(sort => ({dir: sort.dir, field: mapProductAttributeNames(sort.field)}))
    options.sort = mappedSortOptions
    const params = getProductQueryParams(query, mappedFacets, mappedFilters, options, null, true)
    return getProductListNew(region, lang, params, false, false, applyCustomFilters)
  }

  const getProductsBySearchTerm = async function (searchTerm, facets, region, lang, options, filters, applyCustomFilters = [], selectScope = null, attributeSelects = [], designs = null) {
    searchTerm = searchTerm.trim()

    let select = getSelectByScope(selectScope, attributeSelects, useUserStore().loggedIn)

    let designWhereQuery = null
    if (designs !== null && Array.isArray(designs)) {
      designWhereQuery = getDesignQuery(designs)
    }
    else if (designs !== null && typeof designs === 'string') {
      designWhereQuery = getDesignQueryForCategory(designs)
    }

    let query = null
    if (designWhereQuery !== null) {
      query = new Query().search(searchTerm).where(designWhereQuery).select(select).includeCount()
    } else {
      query = new Query().search(searchTerm).select(select).includeCount()
    }

    let searchFields = 'Locales/' + lang + ',Locales/' + lang + 'Searchcontent,Sku,legacysku,CopFnstSku,ProductDesign,MaterialPrimary'
    try {
      // add 'CustomerMappings' if user is logged in (user is logged in when a token is present)
      const token = await axios.getToken('getProductsBySearchTerm')
      if (token && token !== '') {
        searchFields += ',CustomerMappings'
      }
    }
    catch {
      // ignore this case
    }
    const params = getProductQueryParams(query, facets, filters, options, searchFields)
    return getProductList(region, lang, params, false, false, applyCustomFilters)
  }
  //for new experimental search
  const getProductsBySearchTermNew = async function (searchTerm, facets, region, lang, options, filters, applyCustomFilters = [], selectScope = null, attributeSelects = [], designs = null) {
    const mappedFacets = facets?.map(facet => mapProductAttributeNames(facet))
    const mappedFilters = filters?.map(filter => ({...filter, shortName: mapProductAttributeNames(filter.shortName), indexPath: mapProductAttributeNames(filter.indexPath)}))
    searchTerm = searchTerm.trim()
    const cleanedAttributeSelects = attributeSelects?.map(attr => mapProductAttributeNames(attr)).filter(attr => !attr.startsWith('characteristics'))

    let select = getSelectByScope(selectScope, cleanedAttributeSelects, useUserStore().loggedIn)
    if (applyCustomFilters?.includes(INCH_FILTER.customQuery)) {
      select.push('locales_' + lang.toUpperCase() + '_Imperial_SalesTextECom')
    } else {
      select.push('locales_' + lang.toUpperCase() + '_Metric_SalesTextECom')
    }

    let designWhereQuery = null
    if (designs !== null && Array.isArray(designs)) {
      designWhereQuery = getDesignQueryNew(designs)
    }
    else if (designs !== null && typeof designs === 'string') {
      designWhereQuery = getDesignQueryForCategoryNew(designs)
    }

    let query = null
    if (designWhereQuery !== null) {
      query = new Query().search(searchTerm).where(designWhereQuery).select(select).includeCount()
    } else {
      query = new Query().search(searchTerm).select(select).includeCount()
    }

    let searchFields = 'locales_' + lang.toUpperCase() + '_SalesText,locales_' + lang.toUpperCase() + '_ProductDesignName,sku,legacySkus,copFnstSku,material1Name,competitorDesigns'
    const mappedSortOptions = options.sort?.map(sort => ({dir: sort.dir, field: mapProductAttributeNames(sort.field)}))
    options.sort = mappedSortOptions
    const params = getProductQueryParams(query, mappedFacets, mappedFilters, options, searchFields, true)
    return getProductListNew(region, lang, params, false, false, applyCustomFilters)
  }

  const getAutocompleteSku = async function (region, lang, partialText, isOwnSku) {
    if (partialText && partialText !== '') {
      let searchFields = 'Sku'
      try {
        searchFields = isOwnSku ? 'CustomerMappings' : searchFields + ',CopFnstSku'
      }
      catch {
        // ignore this case
      }
      partialText = partialText.split(' | ')[0]
      let query = new Query()
      let params = query.toPostParams({...{['$searchFields']: searchFields}})
      const headers = {fstRegion: region, contentLanguage: lang}
      const res = await product_axios.get(`products/autocomplete?$search=${partialText}`, {headers, params}).catch(() => {return null})
      return res && res.data ? res.data : null
    }
    return null
  }

  const getAutocompleteSkuNew = async function (region, lang, partialText, isOwnSku) {
    if (partialText && partialText !== '') {
      let searchFields = 'sku'
      try {
        searchFields = isOwnSku ? 'customerSku' : searchFields + ',copFnstSku'
      }
      catch {
        // ignore this case
      }
      partialText = partialText.split(' | ')[0]
      let query = new Query()
      let params = query.toPostParams({...{['$searchFields']: searchFields}})
      const headers = {fstRegion: region, contentLanguage: lang}
      const res = await product_axios_new.get(`products/autocomplete?$search=${partialText}`, {headers, params}).catch(() => {return null})
      return res?.data ?? null
    }
    return null
  }

  const getCustomerSuggestions = async function (searchTerm, region, lang) {
    let body = {search: searchTerm}
    const headers = {fstRegion: region, contentLanguage: lang}
    const res = await product_axios_new.post('suggestions/$bulkRequest', body, {headers}).catch(() => {return null})
    return res?.data ? res.data : null
  }

  const getProductsBySearchSku = async function (searchTerm, region, lang, top, exactMatch, cancellable, hasRightForBuy, hasRightForRequest, plantCode, applyCustomFilters = [], selectScope = null) {
    const options = {
      pagination: {
        take: top || 10,
        skip: 0
      },
      sort: []
    }
    const searchTermWithAsterisk = !exactMatch && searchTerm.slice(-1) !== '*' ? searchTerm + '*' : searchTerm

    let select = getSelectByScope(selectScope, [], useUserStore().loggedIn)

    let query = new Query().search(searchTermWithAsterisk).select(select).includeCount()
    let searchFields = 'Sku'
    try {
      // add 'CustomerMappings' if user is logged in (user is logged in when a token is present)
      const token = await axios.getToken('getProductsBySearchSku')
      if (token && token !== '') {
        searchFields += ',CustomerMappings'
      }
    }
    catch {
      // ignore this case
    }

    let apply = []
    if (plantCode && typeof plantCode === 'string' && plantCode !== 'default-plant') {
      apply.push('`(alternativePlantCode eq \'' + plantCode + '\')`')
    }

    if (hasRightForBuy && hasRightForRequest) {
      apply.push('`(buyable eq true or requestable eq true)`')
    } else if (hasRightForBuy && !hasRightForRequest) {
      apply.push('`(buyable eq true)`')
    } else if (!hasRightForBuy && hasRightForRequest) {
      apply.push('`(requestable eq true)`')
    }

    applyCustomFilters.forEach((filter) => {
      apply.push(filter)
    })

    const params = getProductQueryParams(query, null, null, options, searchFields)
    return getProductList(region, lang, params, false, cancellable, apply)
  }

  return {
    getProductBySku,
    getProductBySkuNew,
    getProductsBySkus,
    getProductsBySkusNew,
    getProductList,
    getProductListNew,
    getProductsForDesigns,
    getProductsForDesignsNew,
    getProductsBySearchTerm,
    getProductsBySearchTermNew,
    getAutocompleteSku,
    getAutocompleteSkuNew,
    getProductsBySearchSku,
    getCustomerSuggestions
  }
}
